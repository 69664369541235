import { useState } from "react";
import { Link } from 'react-router-dom';
import './css/Tab.css';

export default function Tabs() {
    const [toggleState, setToggleState] = useState(0);
    const [currentCategory, setCurrentCategory] = useState('Vehicles');
    const [category, setCategory] = useState([
        {
            name: 'Vehicles',
            sub: ['Cars', 'Motorbikes & Scooters', 'Trucks, Vans & Buses', 'Auto Parts & Accessories', 'Bicycles and Three Wheelers']
        }, 
        {
            name: 'Properties',
            sub: ['Houses', 'Apartment & Flats', 'Commerical Property', 'Garages', 'Plots & Land']
        }, 
        {
            name: 'Technology',
            sub: ['Computers', 'Phone', 'Cameras', 'TV', 'Other']
        },
        {
            name: 'Pets',
            sub: ['Pets', 'Farm Animals', 'Other']
        }, 
        {
            name: 'Baby',
            sub: ['Toys', 'Baby Care', 'Baby Clothes', 'Parms & Strollers', 'Accessories']
        }, 
        {
            name: 'Fashion',
            sub: ['Men\'s Clothes', 'Women\'s Clothes', 'Men\'s Shoes', 'Women\'s Shoes', 'Watches', 'Jewellery']
        },
        {
            name: 'Jobs',
            sub: ['Accounting', 'Advertisting', 'Child Care', 'Construction', 'Computer & IT', 'Driver', 'Engineering', 'Farming', 'Gardening', 'Hotel', 'Legal', 'Restaurant', 'Retail', 'Security']
        },
        {
            name: 'Furniture',
            sub: ['Furniture', 'Hone Accessories', 'Kitchen Ware']
        }, 
        {
            name: 'Agriculture',
            sub: ['Farm Equipment', 'Livestock', 'Livestock Feed', 'Farm Produce']
        }, 
        {
            name: 'Tools',
            sub: ['Garage Tools', 'Manufacturing Equipment', 'Garden Tools', 'Printing Equipment', 'Other']
        },
        {
            name: 'Services',
            sub: ['Tickets', 'Travel & Visa', 'Business & Technical Service', 'Domestic & Personal', 'Events & Hospitality']
        }
    ]);

    const toggleTab = (index, name) => {
        setToggleState(index);
        setCurrentCategory(name)
    }

    const filteredList = category.filter((item) => item.name == currentCategory);

    return(
        <div className="main"> 
            <div className="tabs_container">

                <div className="tab_block">

                    {category.map((result, index) => {
                        return(
                            <div 
                            className={toggleState == index ? "tabs active_tab": "tabs"}
                            key={index}
                            onClick={() => toggleTab(index, result.name)}>
                                <p>{ result.name }</p>
                            </div>
                        )
                    })}
                </div>

                <div className="tab_content">
                   {filteredList.map((result) => (
                    result.sub.map((res) => (
                        <Link to={`/results/${res.toLowerCase()}`}>{ res }</Link>
                    ))
                   ))}
                </div>
            </div>
        </div>
    )
}