import formatDistanceToNow from 'date-fns/formatDistanceToNow';
import '../pages/css/Message.css';

export default function Message({ message, own }) {
    const user = JSON.parse(localStorage.getItem('user'));

    return(
        <div className={ user.id == message.sender ? 'message own' : 'message'}>
            <div className="message_top">
                <p className='message_text'>{ message.text }</p>
            </div>
            <div className="message_bottom">
                {/* <p>{formatDistanceToNow(new Date(message.createdAt), { addSuffix: true })}</p> */}
            </div>
        </div>
    )
}