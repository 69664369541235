import { useParams, Link } from 'react-router-dom';
import { categories } from '../data/categories';

export default function SubCategoryList() {
    const { category } = useParams();

    const name = category.replace(/\w+/g,
    function(w){return w[0].toUpperCase() + w.slice(1).toLowerCase();});

    const filteredList = categories.filter((item) => item.name == name);

    return(
        <div>
            <section className="category_block">
            <h1>{ name }</h1>
            </section>
            <div className='tab_content sub_category_block'>
                {filteredList.map((result) => (
                    result.sub.map((res, index) => (
                        <Link to={`/subcategory/${res.url}`} key={index}>{ res.name }</Link>
                    ))
                ))}
            </div>
        </div>
    )
}