import { useState, useEffect} from 'react';
import { useParams } from 'react-router-dom';
import './css/Listing.css';


export default function Listing () {
    const { id } = useParams();
    const user = JSON.parse(localStorage.getItem('user'));
    const [listing, setListing] = useState({});
    const [images, setImages] = useState([]);
    const [currentIndex, setCurrentIndex] = useState(0);
    const [message, setMessage] = useState('');

    const getSingleListing = async () => {
        const response = await fetch(`https://sawwan.onrender.com/api/listings/${id}`);
        const json = await response.json();
        setListing(json);
        setImages(json.images);
    }

    const sendConvoMessage = async (conversationId, newMessage) => {

        const data = {
            sender: user.id,
            text: newMessage,
            conversationId: conversationId
        };


        const response = await fetch(`https://sawwan.onrender.com/api/messages/`, {
            method: 'POST',
            body: JSON.stringify(data),
            headers: {
                'Content-Type': 'application/json'
            }
        });

        const json = await response.json();

    }
    const sendMessage = async (conversationId, newMessage) => {

        const data = {
            sender: user.id,
            text: newMessage,
            conversationId: conversationId
        };


        const response = await fetch(`https://sawwan.onrender.com/api/messages/`, {
            method: 'POST',
            body: JSON.stringify(data),
            headers: {
                'Content-Type': 'application/json'
            }
        });

        const json = await response.json();

        if(response.ok){
            window.location.assign('/messages');
        }

    }

    const createConversation = async (recipent) => {

        const listingMessage = `Message from this listing: "${listing.title}"`
        const newMessage = `${message}`

        if(!message){
            return;
        }


        const participants = {
            senderId: user.id,
            receiverId: recipent
        }

        const response = await fetch('https://sawwan.onrender.com/api/conversations', {
            method: 'POST',
            body: JSON.stringify(participants),
            headers: {
                'Content-Type': 'application/json'
            }
        });

        const json = await response.json();

        if(response.ok){
            sendConvoMessage(json._id, listingMessage);
            sendMessage(json._id, newMessage);
        }
    }

    const prev = () => {
        
        if(currentIndex <= 0){
            setCurrentIndex(0);
        } else {

            setCurrentIndex(currentIndex - 1)
        }


    }

    const next = () => {

        if(currentIndex >= images.length - 1){
            setCurrentIndex(images.length - 1);
        } else {

            setCurrentIndex(currentIndex + 1)
        } 


    }


    useEffect(() => {
        getSingleListing();

        
    }, [currentIndex])


    return(
        <main>
            <section className="listing_page">
                <div className='carousel'>
                    <div className='image_container'>
                        {images.map((image, index) => (
                            <img src={image} alt='Photo' key={index} className={`image ${currentIndex == index ? 'showCurrent' : ''}`}/>
                        ))}
                    </div>
                </div>
                <p>{currentIndex + 1}/{ images.length }</p>
                <div className='carousel_control'>
                    <p onClick={prev}>Previous</p>
                    <p onClick={next}>Next</p>
                </div>
                { user != null && user.id != listing.id &&  
                <div className='message_seller_block'>
                    <label>Start Chat</label>
                    <textarea className='message_seller_input' value={message} onChange={(e) => setMessage(e.target.value)}></textarea>
                    <button className='button message_seller' onClick={() => createConversation(listing.id)}>Message Seller</button> 
                </div>}
                <>
                    <h1>{ listing.title }</h1>
                    <h3>{ listing.price } Ksh.</h3>
                    <p className='align_self_start'>{ listing.description }</p>
                </>
            </section>
        </main>
    )
}