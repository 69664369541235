export const tvs = [
    "Samsung",
    "LG",
    "Sony",
    "Panasonic",
    "Vizio",
    "Toshiba",
    "Hisense",
    "Philips",
    "Sharp",
    "TCL"
  ];