import { useState, useEffect, useRef } from 'react';
import { useParams } from 'react-router-dom';
import { carList } from '../data/cars'
import { motorcycleBrands } from '../data/motorcycles';
import { commercialVehicles} from '../data/commercialVehicles';
import { phones } from '../data/phones';
import { computers } from '../data/computers';
import { tvs } from '../data/tvs';
import { cameras } from '../data/cameras';
import  { locations } from '../data/locations';

export default function CreatePost() {
    const user = JSON.parse(localStorage.getItem('user'));
    const { category } = useParams();
    const [title, setTitle] = useState('');
    const [price, setPrice] = useState('');
    const [location, setLocation] = useState('Nairobi');
    const [carBrand, setCarBrand] = useState('Seat');
    const [carModel, setCarModel] = useState('Alhambra');
    const [year, setYear] = useState('');
    const [milage, setMilage] = useState('');
    const [fuel, setFuel] = useState('Diesel');
    const [transmisson, setTransmission] = useState('Automatic');
    const [description, setDescription] = useState('');

    const [motorCycleBrand, setMotorCycleBrand] = useState('Honda');

    const [truckBrand, setTruckBrand] = useState('Ford');

    const [salary, setSalary] = useState('');
    const [positonType, setPositionType] = useState('Full-time');

    const [propertyType, setPropertyType] = useState('Apartments');
    const [bedRooms, setBedRooms] = useState('1');
    const [bathRooms, setBathRooms] = useState('1');
    const [totalArea, setTotalArea] = useState('');
    const [furnishing, setFurnishing] = useState('Furnished');
    const [listedBy, setListedBy] = useState('Builder')
    const [parking, setParking] = useState('1')

    const [plotArea, setPlotArea] = useState('');
    const [length, setLength] = useState('');
    const [breadth, setBreadth] = useState('');

    const [bikeBrand, setBikeBrand] = useState('Hero');

    const [autoPart, setAutoPart] = useState('Wheels & Tyres');

    const [computerBrand, setComputerBrand] = useState('Apple');

    const [phoneBrand, setPhoneBrand] = useState('Apple');

    const [cameraBrand, setCameraBrand] = useState('Canon');

    const [tvBrand, setTvBrand] = useState('')

    const [images, setImages] = useState([]);

    const [post, setPost] = useState({});

    const inputRef = useRef();

    const handleInputChange = (e) => {
        const files = Array.from(e.target.files);

        files.forEach(file => {
            const reader = new FileReader();
            reader.readAsDataURL(file);
            reader.onloadend = () => {
                setImages(oldArray => [...oldArray, reader.result]);
            }
        });
    }

    const handleClick = () => {
        inputRef.current.click();
    }

    const handleSubmit = async(e) => {
        e.preventDefault();
        const id = user.id;

        // const data = {
        //     id,
        //     title,
        //     price,
        //     carBrand,
        //     carModel,
        //     year,
        //     milage,
        //     fuel,
        //     transmisson,
        //     description,
        //     motorCycleBrand,
        //     truckBrand,
        //     salary,
        //     positonType,
        //     bedRooms,
        //     bathRooms,
        //     propertyType,
        //     furnishing,
        //     listedBy,
        //     parking,
        //     plotArea,
        //     length,
        //     breadth,
        //     bikeBrand,
        //     autoPart,
        //     computerBrand,
        //     phoneBrand,
        //     cameraBrand
        // }

        const data = {
            id,
            category,
            location,
            title,
            price,
            description,
            images
        };

        setPost(data);



        if(category == 'cars'){
            const data = {
                id,
                title,
                price,
                images,
                location,
                category,
                carBrand,
                carModel,
                year,
                milage,
                fuel,
                transmisson,
                description
            };

            setPost(data);
        }

        if(category == 'motorbikes'){
        
            const data = {
                id,
                title,
                price,
                location,
                category,
                images,
                motorCycleBrand,
                year,
                milage,
                description
            };

            setPost(data);
        }

        if(category == 'trucks'){

            const data = {
                id,
                title,
                price,
                location,
                images,
                category,
                truckBrand,
                year,
                milage,
                description 
            };

            setPost(data);
        }

        if(category.includes('jobs')){
            const jobData = {
                id,
                title,
                price,
                images,
                location,
                category,
                salary,
                positonType,
                description
            };

            setPost(data);
        }

        if(category == 'for-sale-houses'){
            const data = {
                id,
                title,
                price,
                images,
                location,
                category,
                bedRooms,
                bathRooms,
                propertyType,
                totalArea,
                furnishing,
                listedBy,
                parking,
                description
            };

            setPost(data);
        }
        
        if(category == 'for-rent-houses'){
            const data = {
                id,
                title,
                price,
                images,
                location,
                category,
                bedRooms,
                bathRooms,
                propertyType,
                totalArea,
                furnishing,
                listedBy,
                parking,
                description
            };

            setPost(data);
        }

        if(category == 'for-sale-commercial'){
            const data = {
                id,
                title,
                price,
                location,
                images,
                category,
                totalArea,
                furnishing,
                listedBy,
                parking,
                description
            };

            setPost(data);
        }

    

        if(category == 'for-rent-commercial'){
            const data = {
                id,
                title,
                price,
                location,
                images,
                category,
                totalArea,
                furnishing,
                listedBy,
                parking,
                description
            };

            setPost(data);
        }

        if(category == 'land'){

            const data = {
                id,
                title,
                price,
                location,
                images,
                category,
                plotArea,
                length,
                breadth,
                description
            };

            setPost(data)

        }
    
        if(category == 'parts'){

            const data = {
                id,
                title,
                price,
                location,
                images,
                category,
                autoPart, 
                description
            }

            setPost(data)
        }

        if(category == 'bikes'){

            const data = {
                id,
                title,
                price,
                location,
                images,
                category,
                bikeBrand,
                description
            };

            setPost(data)
        }

        if(category == 'computers'){

            const data = {
                id,
                title,
                price,
                location,
                images,
                category,
                computerBrand,
                description
            }

            setPost(data);
        }

        if(category == 'phones'){

            const data = {
                id,
                title,
                price,
                location,
                images,
                category,
                phoneBrand,
                description
            }

            setPost(data)
        }

        if(category == 'cameras'){

            const data = {
                id,
                title,
                price,
                location,
                images,
                category,
                cameraBrand,
                description
            }

            setPost(data)
        }


        if(category == 'tv'){
            
            const data = {
                id,
                title,
                price,
                location,
                images,
                category,
                tvBrand,
                description
            }
        }

        if(category.includes('jobs')){
            
            const data = {
                id,
                title,
                location,
                salary,
                positonType,
                category,
                description
            }

            setPost(data);
        }


        console.log(post);
        const response = await fetch('https://sawwan.onrender.com/api/listings', {
            method: 'POST',
            body: JSON.stringify(post),
            headers: {
                'Content-Type': 'application/json'
            }
        });

        const json = await response.json();
        
        if(response.ok){
            console.log('Post created')
        }
        
    }

    if(category == 'cars'){
        return(
            <main>
                <h1 className='upload_title'>POST YOUR AD</h1>
                <form className='upload' onSubmit={handleSubmit}>
                    <label>Ad title:</label>
                    <input type='text' value={title} onChange={(e) => setTitle(e.target.value)}/>
                    <label>Location</label>
                        <select onChange={(e) => setLocation(e.target.value)}>
                        {
                            locations.map((location, index) => (
                                <option key={index}>{ location }</option>
                            ))
                        }
                </select>
                    <label>Price: </label>
                    <input type='number' placeholder='Ksh.' value={price} onChange={(e) => setPrice(e.target.value)}/>
                    <label>Brand:</label>
                    <select onChange={(e) => setCarBrand(e.target.value)}>
                        {carList.map((car, index) => (
                            <option key={index}>{ car.brand }</option>
                        ))}
                    </select>
                    <label>Model:</label>
                    <select onChange={(e) => setCarModel(e.target.value)}>
                        { carList.filter((car) => car.brand == carBrand).map((car) => (
                            car.models.map((model, index) => (
                                <option key={index}>{ model }</option>
                            ))
                        ))}
                    </select>
                    <label>Year:</label>
                    <input type='number' value={year} onChange={(e) => setYear(e.target.value)}/>
                    <label>KM Driven:</label>
                    <input type='number' value={milage} onChange={(e) => setMilage(e.target.value)}/>
                    <label>Fuel:</label>
                    <select onChange={(e) => setFuel(e.target.value)}>
                        <option>Petrol</option>
                        <option>Diesel</option>
                        <option>Electric</option>
                        <option>CNG & Hybrids</option>
                        <option>LPG</option>
                    </select>
                    <label>Transmisson:</label>
                    <select onChange={(e) => setTransmission(e.target.value)}>
                        <option>Automatic</option>
                        <option>Manual</option>
                    </select>
                    <label>Description:</label>
                    <textarea rows="10" cols="50" value={description} onChange={(e) => setDescription(e.target.value)}>
                    </textarea>
                    <label>Upload Photos:</label>
                    <input type='file' ref={inputRef} className='input_hide' onChange={handleInputChange} accept="image/png, image/jpeg" multiple/>
                    <div className='upload_button_block'>
                        <div className='file_select' onClick={handleClick}>{ images.length > 0 ? ` ${images.length} File(s) Selected` : 'Click to Select'} </div>
                    </div>
                    <button className='upload_button button'>Post Ad</button>
                </form>
            </main>
        )
    }

    if(category == 'motorbikes'){
       return( 
        <main>
                <h1 className='upload_title'>POST YOUR AD</h1>
                <form className='upload' onSubmit={handleSubmit}>
                        <label>Ad title:</label>
                        <input type='text' value={title} onChange={(e) => setTitle(e.target.value)}/>

                        <label>Location</label>
                        <select onChange={(e) => setLocation(e.target.value)}>
                            {      
                                locations.map((location, index) => (
                                    <option key={index}>{ location }</option>
                                ))
                            }
                        </select>

                        <label>Price: </label>
                        <input type='number' placeholder='Ksh.' value={price} onChange={(e) => setPrice(e.target.value)}/>
                        <label>Brand:</label>
                        <select onChange={(e) => setMotorCycleBrand(e.target.value)}>
                            {motorcycleBrands.map((brand, index) => (
                                <option key={index}>{ brand }</option>
                            ))}
                        </select>
                        <label>Year: </label>
                        <input type='text' value={year} onChange={(e) => setYear(e.target.value)}/>
                        <label>KM Driven:</label>
                        <input type='number' value={milage} onChange={(e) => setMilage(e.target.value)}/>
                        <label>Description:</label>
                        <textarea rows="10" cols="50" value={description} onChange={(e) => setDescription(e.target.value)}>

                        </textarea>
                        <label>Upload Photos:</label>
                        <input type='file' ref={inputRef} className='input_hide' onChange={handleInputChange} accept="image/png, image/jpeg" multiple/>
                        <div className='upload_button_block'>
                            <div className='file_select' onClick={handleClick}>{ images.length > 0 ? ` ${images.length} File(s) Selected` : 'Click to Select'} </div>
                        </div>
                        <button className='upload_button button'>Post Ad</button>
                </form>
            </main>
        )
    }

    if(category == 'trucks'){
        return( 
         <main>
                 <h1 className='upload_title'>POST YOUR AD</h1>
                 <form className='upload' onSubmit={handleSubmit}>
                         <label>Ad title:</label>
                         <input type='text' value={title} onChange={(e) => setTitle(e.target.value)}/>

                         <label>Location</label>
                        <select onChange={(e) => setLocation(e.target.value)}>
                            {
                                locations.map((location, index) => (
                                <option key={index}>{ location }</option>
                                ))
                            }
                        </select>
 
                         <label>Price: </label>
                         <input type='number' placeholder='Ksh.' value={price} onChange={(e) => setPrice(e.target.value)}/>
                         <label>Brand:</label>
                         <select onChange={(e) => setTruckBrand(e.target.value)}>
                             {commercialVehicles.map((brand, index) => (
                                 <option key={index}>{ brand }</option>
                             ))}
                         </select>

                         <label>Year: </label>
                         <input type='text' value={year} onChange={(e) => setYear(e.target.value)}/>
                         <label>KM Driven:</label>
                         <input type='number'value={milage} onChange={(e) => setMilage(e.target.value)}/>
                         <label>Description:</label>
                         <textarea rows="10" cols="50" value={description} onChange={(e) => setDescription(e.target.value)}>
 
                         </textarea>
                         <label>Upload Photos:</label>
                         <input type='file' ref={inputRef} className='input_hide' onChange={handleInputChange} accept="image/png, image/jpeg" multiple/>
                         <div className='upload_button_block'>
                            <div className='file_select' onClick={handleClick}>{ images.length > 0 ? ` ${images.length} File(s) Selected` : 'Click to Select'} </div>
                        </div>
                        <button className='upload_button button'>Post Ad</button>
                 </form>
             </main>
         )
    }

    if(category.includes('jobs')){
        return(<main>
            <h1 className='upload_title'>POST YOUR AD</h1>
            <form className='upload' onSubmit={handleSubmit}>
                <label>Ad title:</label>
                <input type='text' value={title} onChange={(e) => setTitle(e.target.value)}/>

                <label>Location</label>
                <select onChange={(e) => setLocation(e.target.value)}>
                    {
                        locations.map((location, index) => (
                            <option key={index}>{ location }</option>
                        ))
                    }
                </select>

                <label>Salary</label>
                <input type='number' placeholder='Ksh.' value={salary} onChange={(e) => setSalary(e.target.value)}/>

                <label>Position Type:</label>
                <select onChange={(e) => setPositionType(e.target.value)}>
                    <option>Full-time</option>
                    <option>Part Time</option>
                    <option>Contract</option>
                    <option>Temporary</option>
                </select>
                <label>Description:</label>
                <textarea rows="10" cols="50" value={description} onChange={(e) => setDescription(e.target.value)}>

                </textarea>
                <label>Upload Photos:</label>
                <input type='file' ref={inputRef} className='input_hide' onChange={handleInputChange} accept="image/png, image/jpeg" multiple/>
                <div className='upload_button_block'>
                    <div className='file_select' onClick={handleClick}>{ images.length > 0 ? ` ${images.length} File(s) Selected` : 'Click to Select'} </div>
                </div>
                <button className='upload_button button'>Post Ad</button>
            </form>
        </main>)
    }

    if(category === 'for-sale-houses'){
        return( 
            <main>
                <h1 className='upload_title'>POST YOUR AD</h1>
                <form className='upload' onSubmit={handleSubmit}>
                    <label>Ad title:</label>
                    <input type='text' value={title} onChange={(e) => setTitle(e.target.value)}/>

                    <label>Location</label>
                    <select onChange={(e) => setLocation(e.target.value)}>
                        {
                            locations.map((location, index) => (
                                <option key={index}>{ location }</option>
                            ))
                         }
                    </select>
        
                    <label>Price: </label>
                    <input type='number' placeholder='Ksh.' value={price} onChange={(e) => setPrice(e.target.value)}/>
                    <label>Type:</label>
                    <select onChange={(e) => setPropertyType(e.target.value)}>
                        <option>Apartments</option>
                        <option>Builder Floors</option>
                        <option>Farm Houses</option>
                        <option>Houses & Villas</option>
                    </select>

                    <label>Bedrooms:</label>
                    <select onChange={(e) => setBedRooms(e.target.value)}>
                        <option>1</option>
                        <option>2</option>
                        <option>3</option>
                        <option>4</option>
                        <option>4+</option>
                    </select>

                    <label>Bathrooms:</label>
                    <select onChange={(e) => setBathRooms(e.target.value)}>
                        <option>1</option>
                        <option>2</option>
                        <option>3</option>
                        <option>4</option>
                        <option>4+</option>
                    </select>

                    <label>Total Area (sqm):</label>
                    <input type='text' value={totalArea} onChange={(e) => setTotalArea(e.target.value)}/>

                    <label>Furnishing:</label>
                    <select onChange={(e) => setFurnishing(e.target.value)}>
                        <option>Furnished</option>
                        <option>Semi-Furnished</option>
                        <option>Unfurnished</option>
                    </select>

                    <label>Listed By:</label>
                    <select onChange={(e) => setListedBy(e.target.value)}>
                        <option>Builder</option>
                        <option>Agent</option>
                        <option>Owner</option>
                    </select>

                    <label>Parking:</label>
                    <select onChange={(e) => setParking(e.target.value)}>
                        <option>1</option>
                        <option>2</option>
                        <option>3</option>
                        <option>4</option>
                        <option>4+</option>
                    </select>

                    <label>Description:</label>
                    <textarea rows="10" cols="50" value={description} onChange={(e) => setDescription(e.target.value)}>
        
                    </textarea>
                    <label>Upload Photos:</label>
                    <input type='file' ref={inputRef} className='input_hide' onChange={handleInputChange} accept="image/png, image/jpeg" multiple/>
                    <div className='upload_button_block'>
                        <div className='file_select' onClick={handleClick}>{ images.length > 0 ? ` ${images.length} File(s) Selected` : 'Click to Select'} </div>
                    </div>
                    <button className='upload_button button'>Post Ad</button>
                </form>
                </main>
            )
    }

    if(category === 'for-rent-houses'){
        return( 
            <main>
                <h1 className='upload_title'>POST YOUR AD</h1>
                <form className='upload' onSubmit={handleSubmit}>
                    <label>Ad title:</label>
                    <input type='text' value={title} onChange={(e) => setTitle(e.target.value)}/>

                    <label>Location</label>
                    <select onChange={(e) => setLocation(e.target.value)}>
                        {
                            locations.map((location, index) => (
                                <option key={index}>{ location }</option>
                            ))
                         }
                    </select>
        
                    <label>Price: </label>
                    <input type='number' placeholder='Ksh.' value={price} onChange={(e) => setPrice(e.target.value)}/>
                    <label>Type:</label>
                    <select onChange={(e) => setPropertyType(e.target.value)}>
                        <option>Apartments</option>
                        <option>Builder Floors</option>
                        <option>Farm Houses</option>
                        <option>Houses & Villas</option>
                    </select>

                    <label>Bedrooms:</label>
                    <select onChange={(e) => setBedRooms(e.target.value)}>
                        <option>1</option>
                        <option>2</option>
                        <option>3</option>
                        <option>4</option>
                        <option>4+</option>
                    </select>

                    <label>Bathrooms:</label>
                    <select onChange={(e) => setBathRooms(e.target.value)}>
                        <option>1</option>
                        <option>2</option>
                        <option>3</option>
                        <option>4</option>
                        <option>4+</option>
                    </select>

                    <label>Total Area (sqm):</label>
                    <input type='text' value={totalArea} onChange={(e) => setTotalArea(e.target.value)}/>

                    <label>Furnishing:</label>
                    <select onChange={(e) => setFurnishing(e.target.value)}>
                        <option>Furnished</option>
                        <option>Semi-Furnished</option>
                        <option>UnFurnished</option>
                    </select>

                    <label>Listed By:</label>
                    <select onChange={(e) => setListedBy(e.target.value)}>
                        <option>Builder</option>
                        <option>Agent</option>
                        <option>Owner</option>
                    </select>

                    <label>Parking:</label>
                    <select onChange={(e) => setParking(e.target.value)}>
                        <option>1</option>
                        <option>2</option>
                        <option>3</option>
                        <option>4</option>
                        <option>4+</option>
                    </select>

                    <label>Description:</label>
                    <textarea rows="10" cols="50" value={description} onChange={(e) => setDescription(e.target.value)}>
        
                    </textarea>
                    <label>Upload Photos:</label>
                    <input type='file' ref={inputRef} className='input_hide' onChange={handleInputChange} accept="image/png, image/jpeg" multiple/>
                    <div className='upload_button_block'>
                        <div className='file_select' onClick={handleClick}>{ images.length > 0 ? ` ${images.length} File(s) Selected` : 'Click to Select'} </div>
                    </div>
                    <button className='upload_button button'>Post Ad</button>
                    </form>
                </main>
            )
    }

    if(category === 'for-sale-commercial'){
        return( 
            <main>
                <h1 className='upload_title'>POST YOUR AD</h1>
                <form className='upload' onSubmit={handleSubmit}>
                    <label>Ad title:</label>
                    <input type='text' value={title} onChange={(e) => setTitle(e.target.value)}/>

                    <label>Location</label>
                    <select onChange={(e) => setLocation(e.target.value)}>
                        {
                            locations.map((location, index) => (
                                <option key={index}>{ location }</option>
                            ))
                         }
                    </select>
        
                    <label>Price: </label>
                    <input type='number' placeholder='Ksh.' value={price} onChange={(e) => setPrice(e.target.value)}/>

                    <label>Total Area (sqm):</label>
                    <input type='text' value={totalArea} onChange={(e) => setTotalArea(e.target.value)}/>

                    <label>Furnishing:</label>
                    <select onChange={(e) => setFurnishing(e.target.value)}>
                        <option>Furnished</option>
                        <option>Semi-Furnished</option>
                        <option>Unfurnished</option>
                    </select>

                    <label>Listed By:</label>
                    <select onChange={(e) => setListedBy(e.target.value)}>
                        <option>Builder</option>
                        <option>Agent</option>
                        <option>Owner</option>
                    </select>

                    <label>Parking:</label>
                    <select onChange={(e) => setParking(e.target.value)}>
                        <option>1</option>
                        <option>2</option>
                        <option>3</option>
                        <option>4</option>
                        <option>4+</option>
                    </select>

                    <label>Description:</label>
                    <textarea rows="10" cols="50" value={description} onChange={(e) => setDescription(e.target.value)}>
        
                    </textarea>
                    <label>Upload Photos:</label>
                    <input type='file' ref={inputRef} className='input_hide' onChange={handleInputChange} accept="image/png, image/jpeg" multiple/>
                    <div className='upload_button_block'>
                        <div className='file_select' onClick={handleClick}>{ images.length > 0 ? ` ${images.length} File(s) Selected` : 'Click to Select'} </div>
                    </div>
                    <button className='upload_button button'>Post Ad</button>
                </form>
                </main>
            )
    }

    if(category === 'for-rent-commercial'){
        return( 
            <main>
                <h1 className='upload_title'>POST YOUR AD</h1>
                <form className='upload' onSubmit={handleSubmit}>
                    <label>Ad title:</label>
                    <input type='text' value={title} onChange={(e) => setTitle(e.target.value)}/>

                    <label>Location</label>
                    <select onChange={(e) => setLocation(e.target.value)}>
                        {
                            locations.map((location, index) => (
                                <option key={index}>{ location }</option>
                            ))
                         }
                    </select>
        
                    <label>Price: </label>
                    <input type='number' placeholder='Ksh.' value={price} onChange={(e) => setPrice(e.target.value)}/>

                    <label>Total Area (sqm):</label>
                    <input type='text' value={totalArea} onChange={(e) => setTotalArea(e.target.value)}/>

                    <label>Furnishing:</label>
                    <select onChange={(e) => setFurnishing(e.target.value)}>
                        <option>Furnished</option>
                        <option>Semi-Furnished</option>
                        <option>Unfurnished</option>
                    </select>

                    <label>Listed By:</label>
                    <select onChange={(e) => setListedBy(e.target.value)}>
                        <option>Builder</option>
                        <option>Agent</option>
                        <option>Owner</option>
                    </select>

                    <label>Parking:</label>
                    <select onChange={(e) => setParking(e.target.value)}>
                        <option>1</option>
                        <option>2</option>
                        <option>3</option>
                        <option>4</option>
                        <option>4+</option>
                    </select>

                    <label>Description:</label>
                    <textarea rows="10" cols="50" value={description} onChange={(e) => setDescription(e.target.value)}>
        
                    </textarea>
                    <label>Upload Photos:</label>
                    <input type='file' ref={inputRef} className='input_hide' onChange={handleInputChange} accept="image/png, image/jpeg" multiple/>
                    <div className='upload_button_block'>
                        <div className='file_select' onClick={handleClick}>{ images.length > 0 ? ` ${images.length} File(s) Selected` : 'Click to Select'} </div>
                    </div>
                    <button className='upload_button button'>Post Ad</button>
                </form>
                </main>
            )
    }

    if(category === 'land'){
        return( 
            <main>
                <h1 className='upload_title'>POST YOUR AD</h1>
                <form className='upload' onSubmit={handleSubmit}>
                    <label>Ad title:</label>
                    <input type='text' value={title} onChange={(e) => setTitle(e.target.value)}/>

                    <label>Location</label>
                    <select onChange={(e) => setLocation(e.target.value)}>
                        {
                            locations.map((location, index) => (
                                <option key={index}>{ location }</option>
                            ))
                         }
                    </select>
        
                    <label>Price: </label>
                    <input type='number' placeholder='Ksh.' value={price} onChange={(e) => setPrice(e.target.value)}/>

                    <label>Plot Area (sqm):</label>
                    <input type='text' value={plotArea} onChange={(e) => setPlotArea(e.target.value)}/>

                    <label>Length:</label>
                    <input type='text' value={length} onChange={(e) => setLength(e.target.value)}/>

                    <label>Breadth:</label>
                    <input type='text' value={breadth} onChange={(e) => setBreadth(e.target.value)}/>

                    <label>Description:</label>
                    <textarea rows="10" cols="50" value={description} onChange={(e) => setDescription(e.target.value)}>
        
                    </textarea>
                    
                    <label>Upload Photos:</label>
                    <input type='file' ref={inputRef} className='input_hide' onChange={handleInputChange} accept="image/png, image/jpeg" multiple/>
                    <div className='upload_button_block'>
                        <div className='file_select' onClick={handleClick}>{ images.length > 0 ? ` ${images.length} File(s) Selected` : 'Click to Select'} </div>
                    </div>
                    <button className='upload_button button'>Post Ad</button>
                </form>
                </main>
            )
    }

    return(
        <main>
            <h1 className='upload_title'>POST YOUR AD</h1>
            <form className='upload' onSubmit={handleSubmit}>
                <label>Ad title:</label>
                <input type='text' value={title} onChange={(e) => setTitle(e.target.value)}/>
                <label>Location</label>
                <select onChange={(e) => setLocation(e.target.value)}>
                    {
                        locations.map((location, index) => (
                            <option key={index}>{ location }</option>
                        ))
                    }
                </select>
                <label>Price: </label>
                <input type='number' placeholder='Ksh.' value={price} onChange={(e) => setPrice(e.target.value)}/>
                <>

                    {
                        category == 'bikes' && 
                        <>
                            <label>Brand:</label>
                            <select onChange={(e) => setBikeBrand(e.target.value)}>
                                <option>Hero</option>
                                <option>Hercules</option>
                                <option>Other Brands</option>
                            </select>
                        </>
                    }
                </>

                <>
                    {
                        category == 'parts' && 
                        <>
                            <label>Type:</label>
                            <select onChange={(e) => setAutoPart(e.target.value)}>
                                <option>Wheels & Tyres</option>
                                <option>Audio & Other Accessories</option>
                                <option>Other Spare Parts</option>
                            </select>
                        </>
                    }
                </>

                <>
                    {
                        category == 'computers' && 
                        <>
                            <label>Brand:</label>
                            <select onChange={(e) => setComputerBrand(e.target.value)}>
                                {computers.map((brand, index) => (
                                    <option key={index}>{ brand }</option>
                                ))}
                            </select>
                        </>
                    }
                </>

                <>
                    {
                        category == 'phones' && 
                        <>
                            <label>Brand:</label>
                            <select onChange={(e) => setPhoneBrand(e.target.value)}>
                                {phones.map((brand, index) => (
                                    <option key={index}>{ brand }</option>
                                ))}
                            </select>
                        </>
                    }
                </>

                <>
                    {
                        category == 'cameras' && 
                        <>
                            <label>Brand:</label>
                            <select onChange={(e) => setCameraBrand(e.target.value)}>
                                {cameras.map((brand, index) => (
                                    <option key={index}>{ brand }</option>
                                ))}
                            </select>
                        </>
                    }
                </>

                <>
                    {
                        category == 'tv' && 
                        <>
                            <label>Brand:</label>
                            <select onChange={(e) => setTvBrand(e.target.value)}>
                                {tvs.map((brand, index) => (
                                    <option key={index}>{ brand }</option>
                                ))}
                            </select>
                        </>
                    }
                </>



                <label>Description:</label>
                <textarea rows="10" cols="50" value={description} onChange={(e) => setDescription(e.target.value)}>

                </textarea>
                <label>Upload Photos:</label>
                <input type='file' ref={inputRef} className='input_hide' onChange={handleInputChange} accept="image/png, image/jpeg" multiple/>
                <div className='upload_button_block'>
                    <div className='file_select' onClick={handleClick}>{ images.length > 0 ? ` ${images.length} File(s) Selected` : 'Click to Select'} </div>
                </div>
                <button className='upload_button button'>Post Ad</button>
            </form>
        </main>
    )
} 