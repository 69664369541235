export const cameras = [
    "Canon",
    "Nikon",
    "Sony",
    "Fujifilm",
    "Panasonic",
    "Olympus",
    "Leica",
    "Pentax",
    "GoPro",
    "Samsung"
  ];
  