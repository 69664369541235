import { useEffect, useState } from 'react';
import { useParams, Link } from 'react-router-dom';
import { categories } from '../data/categories';
import { formatDistanceToNow } from 'date-fns'; 
import { carList } from '../data/cars';
import { motorcycleBrands } from '../data/motorcycles';
import { commercialVehicles} from '../data/commercialVehicles';
import { years } from '../data/year'
import { computers } from '../data/computers';
import { phones } from '../data/phones';
import { cameras } from '../data/cameras';
import { tvs } from '../data/tvs';
import { locations } from '../data/locations';
 
export default function SubCategory() {
    const { category } = useParams();
    const [listings, setListings] = useState([]);
    const [location, setLocation] = useState('');
    const [minPrice, setMinPrice] = useState(0);
    const [maxPrice, setMaxPrice] = useState(999999999999);
    const [carBrand, setCarBrand] = useState('');
    const [carModel, setCarModel] = useState('');

    const [year, setYear] = useState('');
    const [milage, setMilage] = useState('');
    const [fuel, setFuel] = useState('Petrol');
    const [transmisson, setTransmission] = useState('Automatic');

    const [motorCycleBrand, setMotorCycleBrand] = useState('Honda');

    const [truckBrand, setTruckBrand] = useState('');

    const [salary, setSalary] = useState('');
    const [positonType, setPositionType] = useState('');

    const [propertyType, setPropertyType] = useState('');
    const [bedRooms, setBedRooms] = useState('');
    const [bathRooms, setBathRooms] = useState('');
    const [totalArea, setTotalArea] = useState('');
    const [furnishing, setFurnishing] = useState('');
    const [listedBy, setListedBy] = useState('')
    const [parking, setParking] = useState('')

    const [plotArea, setPlotArea] = useState('');
    const [length, setLength] = useState('');
    const [breadth, setBreadth] = useState('');

    const [bikeBrand, setBikeBrand] = useState('');

    const [autoPart, setAutoPart] = useState('Wheels & Tyres');

    const [computerBrand, setComputerBrand] = useState('');

    const [phoneBrand, setPhoneBrand] = useState('');

    const [cameraBrand, setCameraBrand] = useState('');

    const [tvBrand, setTVBrand] = useState('');

    const [showFilters, setShowFilters] = useState(false);

    const getListings = async () => {
        const response = await fetch(`https://sawwan.onrender.com/api/listings/bycategory/${category}`);
        const json = await response.json();
        setListings(json);
    }

    const filteredListings = listings.filter((listing) => {

        if(location == ''){
            return listing;
        }

        if(listing.price >= minPrice && listing.price <= maxPrice && listing.location == location){
            return listing
        }

    });


    useEffect(() => {
        getListings();
    }, [])  

    if(category == 'cars'){
        
        const filteredListings = listings.filter((listing) => {

            if(carBrand == '' || carModel == '' || location == ''){
                return listing
            }

            if(listing.price >= minPrice && listing.price <= maxPrice && listing.carBrand == carBrand && listing.carModel == carModel && listing.fuel == fuel && listing.transmisson == transmisson && listing.year == year && listing.location == location){
                return listing
            }
    
        });

        return(
            <div className='listings_container'>
               <div className={showFilters ? `product_filter show` : `product_filter hide`}>
                                    
                    <div className={`price_filter`}>
                    <h5>Location</h5>
                    <select onChange={(e) => setLocation(e.target.value)}>
                        <option value=''>--</option>
                        {
                            locations.map((location, index) => (
                                <option key={index}>{ location }</option>
                            ))
                        }
                    </select>
                        <h5>Budget:</h5>
                        <div className='budget'>
                            <input type='number' placeholder='min' onChange={(e) => {
                                if(e.target.value == ''){
                                    setMinPrice(0)
                                } else {
                                setMinPrice(parseInt(e.target.value))}
                                }}/>
                            <input type='number' placeholder='max' onChange={(e) => {
                                if(e.target.value == ''){
                                    setMaxPrice(999999999)
                                } else {
                                setMaxPrice(parseInt(e.target.value))}}
                                }/>
                        </div>
                   
                    
                    <h5>Brand:</h5>
                    <select onChange={(e) => setCarBrand(e.target.value)}>
                        {carList.map((car, index) => (
                            <option key={index}>{ car.brand }</option>
                            ))}
                    </select>
                    
                    <h5>Model: </h5>
                    <select onChange={(e) => setCarModel(e.target.value)}>
                            { carList.filter((car) => car.brand == carBrand).map((car) => (
                                car.models.map((model, index) => (
                                    <option key={index}>{ model }</option>
                                ))
                                ))}
                    </select>
                    
                    <h5>Year: </h5>
                    <select onChange={(e) => setYear(e.target.value) }>
                        {years.map((year) => (
                            <option key={year}>{ year }</option>
                        ))}
                    </select>
                
                    
                    <h5>Fuel: </h5>
                    <select onChange={(e) => setFuel(e.target.value)}>
                        <option>Petrol</option>
                        <option>Diesel</option>
                        <option>Electric</option>
                        <option>CNG & Hybrids</option>
                        <option>LPG</option>
                    </select>

                    <h5>Transmission:</h5>
                    <select onChange={(e) => setTransmission(e.target.value)}>
                        <option>Automatic</option>
                        <option>Manual</option>
                    </select>
                    <button className='button hide_for_desktop' onClick={() => setShowFilters(false)}>Apply Filters</button> 
                </div>
            </div>
            <div className={`listings no_block`}>
            { !showFilters &&  <button className='button hide_for_desktop' onClick={() => setShowFilters(true)}>Show Filters</button> }
                { filteredListings.length > 0 && !showFilters ?
                        filteredListings.map((listing) => {
                            return <Link to={`/listing/${listing._id}`} key={listing._id}>
                                <div className="card">
                                    <div className='image_box'>
                                        <img src={listing.images[0]} alt={`${listing.title}`} />
                                        <h3>{ listing.title }</h3>
                                        <p>{ listing.description.substring(0, 50) }....</p>
                                        <h4>{ listing.price } Ksh.</h4>
                                        <p className='card_time'>{formatDistanceToNow(new Date(listing.createdAt), { addSuffix: true })}</p>
                                    </div>
                                </div>
                            </Link>
                    }) : <h3 className={showFilters && 'hide_for_mobile'}>No Results.</h3>
                    }
            </div>
        </div>
    )
    }

    if(category == 'motorbikes'){

        const filteredListings = listings.filter((listing) => {

            if(year == '' || location == ''){
                return listing;
            }

            if(listing.price >= minPrice && listing.price <= maxPrice && listing.motorCycleBrand == motorCycleBrand && listing.year == year && listing.location == location){
                return listing
            }
    
        });
        return(
            <div className='listings_container'>
                <div className={showFilters ? `product_filter show` : `product_filter hide`}>
                    <div className='price_filter'>
                    <h5>Location</h5>
                    <select onChange={(e) => setLocation(e.target.value)}>
                        <option value=''>--</option>
                        {
                            locations.map((location, index) => (
                                <option key={index}>{ location }</option>
                            ))
                        }
                    </select>
                        <h5>Budget:</h5>
                        <div className='budget'>
                            <input type='number' placeholder='min' onChange={(e) => {
                                if(e.target.value == ''){
                                    setMinPrice(0)
                                } else {
                                setMinPrice(parseInt(e.target.value))}
                                }}/>
                            <input type='number' placeholder='max' onChange={(e) => {
                                if(e.target.value == ''){
                                    setMaxPrice(999999999)
                                } else {
                                setMaxPrice(parseInt(e.target.value))}}
                                }/>
                        </div>
                    </div>
                    <label>Brand</label>    
                    <select onChange={(e) => setMotorCycleBrand(e.target.value)}>
                        {
                            motorcycleBrands.map((motorCycle, index) => (
                                <option key={index}>{ motorCycle }</option>
                            ))
                        }
                    </select>

                    <label>Year:</label>
                    <select onChange={(e) => setYear(e.target.value)}>
                        {years.map((year, index) => (
                            <option key={index}>{ year }</option>
                        ))}
                    </select>
                    <button className='button hide_for_desktop' onClick={() => setShowFilters(false)}>Apply Filters</button> 
                </div>
                <div className="listings no_block">
                { !showFilters &&  <button className='button hide_for_desktop' onClick={() => setShowFilters(true)}>Show Filters</button> }
                { filteredListings.length > 0 && !showFilters ? 
                        filteredListings.map((listing) => {
                            return <Link to={`/listing/${listing._id}`} key={listing._id}>
                                <div className="card">
                                    <div className='image_box'>
                                        <img src={listing.images[0]} alt={`${listing.title}`} />
                                        <h3>{ listing.title }</h3>
                                        <p>{ listing.description.substring(0, 50) }....</p>
                                        <h4>{ listing.price } Ksh.</h4>
                                        <p className='card_time'>{formatDistanceToNow(new Date(listing.createdAt), { addSuffix: true })}</p>
                                    </div>
                                </div>
                            </Link>
                        }) : <h3 className={showFilters && 'hide_for_mobile'}>No Results.</h3>
                        }
                </div>
            </div>
        )
    }

    if(category == 'trucks'){
        const filteredListings = listings.filter((listing) => {

            if(year == '' || location == ''){
                return listing;
            }

            if(listing.price >= minPrice && listing.price <= maxPrice && listing.truckBrand == truckBrand && listing.year == year && listing.location == location){
                return listing
            }
    
        });
        return(
            <div className='listings_container'>
                <div className={showFilters ? `product_filter show` : `product_filter hide`}>
                    <div className='price_filter'>
                    <h5>Location</h5>
                    <select onChange={(e) => setLocation(e.target.value)}>
                        <option value=''>--</option>
                        {
                            locations.map((location, index) => (
                                <option key={index}>{ location }</option>
                            ))
                        }
                    </select>
                        <h5>Budget:</h5>
                        <div className='budget'>
                            <input type='number' placeholder='min' onChange={(e) => {
                                if(e.target.value == ''){
                                    setMinPrice(0)
                                } else {
                                setMinPrice(parseInt(e.target.value))}
                                }}/>
                            <input type='number' placeholder='max' onChange={(e) => {
                                if(e.target.value == ''){
                                    setMaxPrice(999999999)
                                } else {
                                setMaxPrice(parseInt(e.target.value))}}
                                }/>
                        </div>
                    </div>
                    <label>Brand:</label>    
                    <select onChange={(e) => setTruckBrand(e.target.value)}>
                        {
                            commercialVehicles.map((truck, index) => (
                                <option key={index}>{ truck }</option>
                            ))
                        }
                    </select>

                    <label>Year:</label>
                    <select onChange={(e) => setYear(e.target.value)}>
                        {years.map((year, index) => (
                            <option key={index}>{ year }</option>
                        ))}
                    </select>
                    <button className='button hide_for_desktop' onClick={() => setShowFilters(false)}>Apply Filters</button> 
                </div>
                <div className="listings no_block">
                { !showFilters &&  <button className='button hide_for_desktop' onClick={() => setShowFilters(true)}>Show Filters</button> }
                { filteredListings.length > 0 && !showFilters? 
                        filteredListings.map((listing) => {
                            return <Link to={`/listing/${listing._id}`} key={listing._id}>
                                <div className="card">
                                    <div className='image_box'>
                                        <img src={listing.images[0]} alt={`${listing.title}`} />
                                        <h3>{ listing.title }</h3>
                                        <p>{ listing.description.substring(0, 50) }....</p>
                                        <h4>{ listing.price } Ksh.</h4>
                                        <p className='card_time'>{formatDistanceToNow(new Date(listing.createdAt), { addSuffix: true })}</p>
                                    </div>
                                </div>
                            </Link>
                        }) : <h3 className={showFilters && 'hide_for_mobile'}>No Results.</h3>
                        }
                </div>
            </div>
        )
    }

    if(category == 'parts'){
        const filteredListings = listings.filter((listing) => {

            if(location == ''){
                return listing;
            }

            if(listing.price >= minPrice && listing.price <= maxPrice && listing.autoPart == autoPart && listing.location == location){
                return listing
            }
    
        });
        return(
            <div className='listings_container'>
                <div className={showFilters ? `product_filter show` : `product_filter hide`}>
                    <div className='price_filter'>
                    <h5>Location</h5>
                    <select onChange={(e) => setLocation(e.target.value)}>
                        <option value=''>--</option>
                        {
                            locations.map((location, index) => (
                                <option key={index}>{ location }</option>
                            ))
                        }
                    </select>
                        <h5>Budget:</h5>
                        <div className='budget'>
                            <input type='number' placeholder='min' onChange={(e) => {
                                if(e.target.value == ''){
                                    setMinPrice(0)
                                } else {
                                setMinPrice(parseInt(e.target.value))}
                                }}/>
                            <input type='number' placeholder='max' onChange={(e) => {
                                if(e.target.value == ''){
                                    setMaxPrice(999999999)
                                } else {
                                setMaxPrice(parseInt(e.target.value))}}
                                }/>
                        </div>
                    </div>
                    <label>Type:</label>
                        <select onChange={(e) => setAutoPart(e.target.value)}>
                            <option>Wheels & Tyres</option>
                            <option>Audio & Other Accessories</option>
                            <option>Other Spare Parts</option>
                        </select>
                        <button className='button hide_for_desktop' onClick={() => setShowFilters(false)}>Apply Filters</button> 
                </div>
                <div className="listings no_block">
                { !showFilters &&  <button className='button hide_for_desktop' onClick={() => setShowFilters(true)}>Show Filters</button> }
                { filteredListings.length > 0 && !showFilters? 
                        filteredListings.map((listing) => {
                            return <Link to={`/listing/${listing._id}`} key={listing._id}>
                                <div className="card">
                                    <div className='image_box'>
                                        <img src={listing.images[0]} alt={`${listing.title}`} />
                                        <h3>{ listing.title }</h3>
                                        <p>{ listing.description.substring(0, 50) }....</p>
                                        <h4>{ listing.price } Ksh.</h4>
                                        <p className='card_time'>{formatDistanceToNow(new Date(listing.createdAt), { addSuffix: true })}</p>
                                    </div>
                                </div>
                            </Link>
                        }) : <h3 className={showFilters && 'hide_for_mobile'}>No Results.</h3>
                        }
                </div>
            </div>
        )
    }

    if(category == 'bikes'){
        const filteredListings = listings.filter((listing) => {

            if(bikeBrand = '' || location == ''){
                return listing;
            }

            if(listing.price >= minPrice && listing.price <= maxPrice && listing.bikeBrand == bikeBrand && listing.location == location){
                return listing
            }
    
        });
        return(
            <div className='listings_container'>
                <div className={showFilters ? `product_filter show` : `product_filter hide`}>
                    <div className='price_filter'>
                    <h5>Location</h5>
                    <select onChange={(e) => setLocation(e.target.value)}>
                        <option value=''>--</option>
                        {
                            locations.map((location, index) => (
                                <option key={index}>{ location }</option>
                            ))
                        }
                    </select>
                        <h5>Budget:</h5>
                        <div className='budget'>
                            <input type='number' placeholder='min' onChange={(e) => {
                                if(e.target.value == ''){
                                    setMinPrice(0)
                                } else {
                                setMinPrice(parseInt(e.target.value))}
                                }}/>
                            <input type='number' placeholder='max' onChange={(e) => {
                                if(e.target.value == ''){
                                    setMaxPrice(999999999)
                                } else {
                                setMaxPrice(parseInt(e.target.value))}}
                                }/>
                        </div>
                    </div>
                    <label>Brand:</label>
                        <select onChange={(e) => setBikeBrand(e.target.value)}>
                           <option>Hero</option>
                           <option>Hercules</option>
                           <option>Other Brands</option>
                        </select>
                        <button className='button hide_for_desktop' onClick={() => setShowFilters(false)}>Apply Filters</button> 
                </div>
                <div className="listings no_block">
                { !showFilters &&  <button className='button hide_for_desktop' onClick={() => setShowFilters(true)}>Show Filters</button> }
                { filteredListings.length > 0 && !showFilters? 
                        filteredListings.map((listing) => {
                            return <Link to={`/listing/${listing._id}`} key={listing._id}>
                                <div className="card">
                                    <div className='image_box'>
                                        <img src={listing.images[0]} alt={`${listing.title}`} />
                                        <h3>{ listing.title }</h3>
                                        <p>{ listing.description.substring(0, 50) }....</p>
                                        <h4>{ listing.price } Ksh.</h4>
                                        <p className='card_time'>{formatDistanceToNow(new Date(listing.createdAt), { addSuffix: true })}</p>
                                    </div>
                                </div>
                            </Link>
                        }) : <h3 className={showFilters && 'hide_for_mobile'}>No Results.</h3>
                        }
                </div>
            </div>
        )
    }

    if(category === 'for-sale-houses'){
        const filteredListings = listings.filter((listing) => {

            if(propertyType == '' || bedRooms == '' || bathRooms == '' || totalArea == '' || furnishing == '' || listedBy == '' || parking == '' || location == ''){
                return listing;
            }

            if(listing.price >= minPrice && listing.price <= maxPrice && listing.propertyType == propertyType && listing.bedRooms == bedRooms && listing.bathRooms == bathRooms && listing.totalArea == totalArea && listing.furnishing == furnishing && listing.listedBy == listedBy && listing.parking == parking && listing.location == location){
                return listing
            }
    
        });
        return(
            <div className='listings_container'>
                <div className={showFilters ? `product_filter show` : `product_filter hide`}>
                    <div className='price_filter'>
                    <h5>Location</h5>
                    <select onChange={(e) => setLocation(e.target.value)}>
                        <option value=''>--</option>
                        {
                            locations.map((location, index) => (
                                <option key={index}>{ location }</option>
                            ))
                        }
                    </select>
                        <h5>Budget:</h5>
                        <div className='budget'>
                            <input type='number' placeholder='min' onChange={(e) => {
                                if(e.target.value == ''){
                                    setMinPrice(0)
                                } else {
                                setMinPrice(parseInt(e.target.value))}
                                }}/>
                            <input type='number' placeholder='max' onChange={(e) => {
                                if(e.target.value == ''){
                                    setMaxPrice(999999999)
                                } else {
                                setMaxPrice(parseInt(e.target.value))}}
                                }/>
                        </div>
                    </div>
                    <label>Type:</label>
                        <select onChange={(e) => setPropertyType(e.target.value)}>
                            <option>Apartments</option>
                            <option>Builder Floors</option>
                            <option>Farm Houses</option>
                            <option>House & Villas</option>
                        </select>

                        <label>Bedrooms:</label>
                    <select onChange={(e) => setBedRooms(e.target.value)}>
                        <option>1</option>
                        <option>2</option>
                        <option>3</option>
                        <option>4</option>
                        <option>4+</option>
                    </select>

                    <label>Bathrooms:</label>
                    <select onChange={(e) => setBathRooms(e.target.value)}>
                        <option>1</option>
                        <option>2</option>
                        <option>3</option>
                        <option>4</option>
                        <option>4+</option>
                    </select>

                    <label>Total Area (sqm):</label>
                    <input type='text' value={totalArea} onChange={(e) => setTotalArea(e.target.value)} className='filter_input'/>

                    <label>Furnishing:</label>
                    <select onChange={(e) => setFurnishing(e.target.value)}>
                        <option>Furnished</option>
                        <option>Semi-Furnished</option>
                        <option>Unfurnished</option>
                    </select>

                    <label>Listed By:</label>
                    <select onChange={(e) => setListedBy(e.target.value)}>
                        <option>Builder</option>
                        <option>Agent</option>
                        <option>Owner</option>
                    </select>

                    <label>Parking:</label>
                    <select onChange={(e) => setParking(e.target.value)}>
                        <option>1</option>
                        <option>2</option>
                        <option>3</option>
                        <option>4</option>
                        <option>4+</option>
                    </select>
                    <button className='button hide_for_desktop' onClick={() => setShowFilters(false)}>Apply Filters</button> 
                </div>
                <div className="listings no_block">
                { !showFilters &&  <button className='button hide_for_desktop' onClick={() => setShowFilters(true)}>Show Filters</button> }
                { filteredListings.length > 0 && !showFilters ? 
                        filteredListings.map((listing) => {
                            return <Link to={`/listing/${listing._id}`} key={listing._id}>
                                <div className="card">
                                    <div className='image_box'>
                                        <img src={listing.images[0]} alt={`${listing.title}`} />
                                        <h3>{ listing.title }</h3>
                                        <p>{ listing.description.substring(0, 50) }....</p>
                                        <h4>{ listing.price } Ksh.</h4>
                                        <p className='card_time'>{formatDistanceToNow(new Date(listing.createdAt), { addSuffix: true })}</p>
                                    </div>
                                </div>
                            </Link>
                        }) : <h3 className={showFilters && 'hide_for_mobile'}>No Results.</h3>
                        }
                </div>
            </div>
        ) 
    }

    if(category === 'for-rent-houses'){
        const filteredListings = listings.filter((listing) => {

            if(propertyType == '' || bedRooms == '' || bathRooms == '' || totalArea == '' || furnishing == '' || listedBy == '' || parking == ''){
                return listing;
            }

            if(listing.price >= minPrice && listing.price <= maxPrice && listing.propertyType == propertyType && listing.bedRooms == bedRooms && listing.bathRooms == bathRooms && listing.totalArea == totalArea && listing.furnishing == furnishing && listing.listedBy == listedBy && listing.parking == parking){
                return listing
            }
    
        });
        return(
            <div className='listings_container'>
                <div className={showFilters ? `product_filter show` : `product_filter hide`}>
                    <div className='price_filter'>
                        <h5>Budget:</h5>
                        <div className='budget'>
                            <input type='number' placeholder='min' onChange={(e) => {
                                if(e.target.value == ''){
                                    setMinPrice(0)
                                } else {
                                setMinPrice(parseInt(e.target.value))}
                                }}/>
                            <input type='number' placeholder='max' onChange={(e) => {
                                if(e.target.value == ''){
                                    setMaxPrice(999999999)
                                } else {
                                setMaxPrice(parseInt(e.target.value))}}
                                }/>
                        </div>
                    </div>
                    <label>Type:</label>
                        <select onChange={(e) => setPropertyType(e.target.value)}>
                            <option>Apartments</option>
                            <option>Builder Floors</option>
                            <option>Farm Houses</option>
                            <option>House & Villas</option>
                        </select>

                        <label>Bedrooms:</label>
                    <select onChange={(e) => setBedRooms(e.target.value)}>
                        <option>1</option>
                        <option>2</option>
                        <option>3</option>
                        <option>4</option>
                        <option>4+</option>
                    </select>

                    <label>Bathrooms:</label>
                    <select onChange={(e) => setBathRooms(e.target.value)}>
                        <option>1</option>
                        <option>2</option>
                        <option>3</option>
                        <option>4</option>
                        <option>4+</option>
                    </select>

                    <label>Total Area (sqm):</label>
                    <input type='text' value={totalArea} onChange={(e) => setTotalArea(e.target.value)} className='filter_input'/>

                    <label>Furnishing:</label>
                    <select onChange={(e) => setFurnishing(e.target.value)}>
                        <option>Furnished</option>
                        <option>Semi-Furnished</option>
                        <option>Unfurnished</option>
                    </select>

                    <label>Listed By:</label>
                    <select onChange={(e) => setListedBy(e.target.value)}>
                        <option>Builder</option>
                        <option>Agent</option>
                        <option>Owner</option>
                    </select>

                    <label>Parking:</label>
                    <select onChange={(e) => setParking(e.target.value)}>
                        <option>1</option>
                        <option>2</option>
                        <option>3</option>
                        <option>4</option>
                        <option>4+</option>
                    </select>
                    <button className='button hide_for_desktop' onClick={() => setShowFilters(false)}>Apply Filters</button> 
                </div>
                <div className="listings no_block">
                { !showFilters &&  <button className='button hide_for_desktop' onClick={() => setShowFilters(true)}>Show Filters</button> }
                { filteredListings.length > 0 && !showFilters? 
                        filteredListings.map((listing) => {
                            return <Link to={`/listing/${listing._id}`} key={listing._id}>
                                <div className="card">
                                    <div className='image_box'>
                                        <img src={listing.images[0]} alt={`${listing.title}`} />
                                        <h3>{ listing.title }</h3>
                                        <p>{ listing.description.substring(0, 50) }....</p>
                                        <h4>{ listing.price } Ksh.</h4>
                                        <p className='card_time'>{formatDistanceToNow(new Date(listing.createdAt), { addSuffix: true })}</p>
                                    </div>
                                </div>
                            </Link>
                        }) : <h3 className={showFilters && 'hide_for_mobile'}>No Results.</h3>
                        }
                </div>
            </div>
        ) 
    }

    if(category === 'for-rent-commercial'){
        const filteredListings = listings.filter((listing) => {

            if(totalArea == '' || furnishing == '' || listedBy == '' || parking == '' || location == ''){
                return listing;
            }

            if(listing.price >= minPrice && listing.price <= maxPrice && listing.totalArea == totalArea && listing.furnishing == furnishing && listing.listedBy == listedBy && listing.parking == parking && listing.location == location){
                return listing
            }
    
        });
        return(
            <div className='listings_container'>
                <div className={showFilters ? `product_filter show` : `product_filter hide`}>
                    <div className='price_filter'>
                    <h5>Location</h5>
                    <select onChange={(e) => setLocation(e.target.value)}>
                        <option value=''>--</option>
                        {
                            locations.map((location, index) => (
                                <option key={index}>{ location }</option>
                            ))
                        }
                    </select>
                        <h5>Budget:</h5>
                        <div className='budget'>
                            <input type='number' placeholder='min' onChange={(e) => {
                                if(e.target.value == ''){
                                    setMinPrice(0)
                                } else {
                                setMinPrice(parseInt(e.target.value))}
                                }}/>
                            <input type='number' placeholder='max' onChange={(e) => {
                                if(e.target.value == ''){
                                    setMaxPrice(999999999)
                                } else {
                                setMaxPrice(parseInt(e.target.value))}}
                                }/>
                        </div>
                    </div>

                    <label>Total Area (sqm):</label>
                    <input type='text' value={totalArea} onChange={(e) => setTotalArea(e.target.value)} className='filter_input'/>

                    <label>Furnishing:</label>
                    <select onChange={(e) => setFurnishing(e.target.value)}>
                        <option>Furnished</option>
                        <option>Semi-Furnished</option>
                        <option>Unfurnished</option>
                    </select>

                    <label>Listed By:</label>
                    <select onChange={(e) => setListedBy(e.target.value)}>
                        <option>Builder</option>
                        <option>Agent</option>
                        <option>Owner</option>
                    </select>

                    <label>Parking:</label>
                    <select onChange={(e) => setParking(e.target.value)}>
                        <option>1</option>
                        <option>2</option>
                        <option>3</option>
                        <option>4</option>
                        <option>4+</option>
                    </select>
                    <button className='button hide_for_desktop' onClick={() => setShowFilters(false)}>Apply Filters</button> 
                </div>
                <div className="listings no_block">
                { !showFilters &&  <button className='button hide_for_desktop' onClick={() => setShowFilters(true)}>Show Filters</button> }
                { filteredListings.length > 0 && !showFilters? 
                        filteredListings.map((listing) => {
                            return <Link to={`/listing/${listing._id}`} key={listing._id}>
                                <div className="card">
                                    <div className='image_box'>
                                        <img src={listing.images[0]} alt={`${listing.title}`} />
                                        <h3>{ listing.title }</h3>
                                        <p>{ listing.description.substring(0, 50) }....</p>
                                        <h4>{ listing.price } Ksh.</h4>
                                        <p className='card_time'>{formatDistanceToNow(new Date(listing.createdAt), { addSuffix: true })}</p>
                                    </div>
                                </div>
                            </Link>
                        }) : <h3 className={showFilters && 'hide_for_mobile'}>No Results.</h3>
                        }
                </div>
            </div>
        ) 
    }

    if(category === 'for-sale-commercial'){
        const filteredListings = listings.filter((listing) => {

            if(totalArea == '' || furnishing == '' || listedBy == '' || parking == '' || location == ''){
                return listing;
            }

            if(listing.price >= minPrice && listing.price <= maxPrice && listing.totalArea == totalArea && listing.furnishing == furnishing && listing.listedBy == listedBy && listing.parking == parking && listing.location == location){
                return listing
            }
    
        });
        return(
            <div className='listings_container'>
                <div className={showFilters ? `product_filter show` : `product_filter hide`}>
                    <div className='price_filter'>
                    <h5>Location</h5>
                    <select onChange={(e) => setLocation(e.target.value)}>
                        <option value=''>--</option>
                        {
                            locations.map((location, index) => (
                                <option key={index}>{ location }</option>
                            ))
                        }
                    </select>
                        <h5>Budget:</h5>
                        <div className='budget'>
                            <input type='number' placeholder='min' onChange={(e) => {
                                if(e.target.value == ''){
                                    setMinPrice(0)
                                } else {
                                setMinPrice(parseInt(e.target.value))}
                                }}/>
                            <input type='number' placeholder='max' onChange={(e) => {
                                if(e.target.value == ''){
                                    setMaxPrice(999999999)
                                } else {
                                setMaxPrice(parseInt(e.target.value))}}
                                }/>
                        </div>
                    </div>

                    <label>Total Area (sqm):</label>
                    <input type='text' value={totalArea} onChange={(e) => setTotalArea(e.target.value)} className='filter_input'/>

                    <label>Furnishing:</label>
                    <select onChange={(e) => setFurnishing(e.target.value)}>
                        <option>Furnished</option>
                        <option>Semi-Furnished</option>
                        <option>Unfurnished</option>
                    </select>

                    <label>Listed By:</label>
                    <select onChange={(e) => setListedBy(e.target.value)}>
                        <option>Builder</option>
                        <option>Agent</option>
                        <option>Owner</option>
                    </select>

                    <label>Parking:</label>
                    <select onChange={(e) => setParking(e.target.value)}>
                        <option>1</option>
                        <option>2</option>
                        <option>3</option>
                        <option>4</option>
                        <option>4+</option>
                    </select>
                    <button className='button hide_for_desktop' onClick={() => setShowFilters(false)}>Apply Filters</button> 
                </div>
                <div className="listings no_block">
                { !showFilters &&  <button className='button hide_for_desktop' onClick={() => setShowFilters(true)}>Show Filters</button> }
                { filteredListings.length > 0 && !showFilters? 
                        filteredListings.map((listing) => {
                            return <Link to={`/listing/${listing._id}`} key={listing._id}>
                                <div className="card">
                                    <div className='image_box'>
                                        <img src={listing.images[0]} alt={`${listing.title}`} />
                                        <h3>{ listing.title }</h3>
                                        <p>{ listing.description.substring(0, 50) }....</p>
                                        <h4>{ listing.price } Ksh.</h4>
                                        <p className='card_time'>{formatDistanceToNow(new Date(listing.createdAt), { addSuffix: true })}</p>
                                    </div>
                                </div>
                            </Link>
                        }) : <h3 className={showFilters && 'hide_for_mobile'}>No Results.</h3>
                        }
                </div>
            </div>
        ) 
    }

    if(category === 'land'){
        const filteredListings = listings.filter((listing) => {

            if(plotArea == '' || location == ''){
                return listing;
            }

            if(listing.price >= minPrice && listing.price <= maxPrice && listing.plotArea == plotArea && listing.location == location){
                return listing
            }
    
        });

        return(
            <div className='listings_container'>
                <div className={showFilters ? `product_filter show` : `product_filter hide`}>
                    <div className='price_filter'>
                    <h5>Location</h5>
                    <select onChange={(e) => setLocation(e.target.value)}>
                        <option value=''>--</option>
                        {
                            locations.map((location, index) => (
                                <option key={index}>{ location }</option>
                            ))
                        }
                    </select>
                        <h5>Budget:</h5>
                        <div className='budget'>
                            <input type='number' placeholder='min' onChange={(e) => {
                                if(e.target.value == ''){
                                    setMinPrice(0)
                                } else {
                                setMinPrice(parseInt(e.target.value))}
                                }}/>
                            <input type='number' placeholder='max' onChange={(e) => {
                                if(e.target.value == ''){
                                    setMaxPrice(999999999)
                                } else {
                                setMaxPrice(parseInt(e.target.value))}}
                                }/>
                        </div>
                    </div>

                    <label>Total Area (sqm):</label>
                    <input type='text' value={totalArea} onChange={(e) => {
                        if(e.target.value == ''){
                            setTotalArea(0)
                        } else {
                        setTotalArea(e.target.value)}}
                        } className='filter_input'/>
                     <button className='button hide_for_desktop' onClick={() => setShowFilters(false)}>Apply Filters</button> 
                   

                </div>
                <div className="listings no_block">
                { !showFilters &&  <button className='button hide_for_desktop' onClick={() => setShowFilters(true)}>Show Filters</button> }
                { filteredListings.length > 0 && !showFilters? 
                        filteredListings.map((listing) => {
                            return <Link to={`/listing/${listing._id}`} key={listing._id}>
                                <div className="card">
                                    <div className='image_box'>
                                        <img src={listing.images[0]} alt={`${listing.title}`} />
                                        <h3>{ listing.title }</h3>
                                        <p>{ listing.description.substring(0, 50) }....</p>
                                        <h4>{ listing.price } Ksh.</h4>
                                        <p className='card_time'>{formatDistanceToNow(new Date(listing.createdAt), { addSuffix: true })}</p>
                                    </div>
                                </div>
                            </Link>
                        }) : <h3 className={showFilters && 'hide_for_mobile'}>No Results.</h3>
                        }
                </div>
            </div>
        ) 
    }


    if(category === 'computers'){
        const filteredListings = listings.filter((listing) => {

            if(computerBrand == '' || location == ''){
                return listing;
            }

            if(listing.price >= minPrice && listing.price <= maxPrice && listing.computerBrand == computerBrand && listing.location == location){
                return listing
            }
    
        });

        return(
            <div className='listings_container'>
                <div className={showFilters ? `product_filter show` : `product_filter hide`}>
                    <div className='price_filter'>
                    <h5>Location</h5>
                    <select onChange={(e) => setLocation(e.target.value)}>
                        <option value=''>--</option>
                        {
                            locations.map((location, index) => (
                                <option key={index}>{ location }</option>
                            ))
                        }
                    </select>
                        <h5>Budget:</h5>
                        <div className='budget'>
                            <input type='number' placeholder='min' onChange={(e) => {
                                if(e.target.value == ''){
                                    setMinPrice(0)
                                } else {
                                setMinPrice(parseInt(e.target.value))}
                                }}/>
                            <input type='number' placeholder='max' onChange={(e) => {
                                if(e.target.value == ''){
                                    setMaxPrice(999999999)
                                } else {
                                setMaxPrice(parseInt(e.target.value))}}
                                }/>
                        </div>
                    </div>

                    <label>Brand:</label>
                    <select onChange={(e) => setComputerBrand(e.target.value)}>
                        { computers.map((computer, index) => (
                            <option key={index}>{ computer }</option>
                        ))}
                    </select>
                   
                    <button className='button hide_for_desktop' onClick={() => setShowFilters(false)}>Apply Filters</button> 
                </div>
                <div className="listings no_block">
                { !showFilters &&  <button className='button hide_for_desktop' onClick={() => setShowFilters(true)}>Show Filters</button> }
                { filteredListings.length > 0 && !showFilters? 
                        filteredListings.map((listing) => {
                            return <Link to={`/listing/${listing._id}`} key={listing._id}>
                                <div className="card">
                                    <div className='image_box'>
                                        <img src={listing.images[0]} alt={`${listing.title}`} />
                                        <h3>{ listing.title }</h3>
                                        <p>{ listing.description.substring(0, 50) }....</p>
                                        <h4>{ listing.price } Ksh.</h4>
                                        <p className='card_time'>{formatDistanceToNow(new Date(listing.createdAt), { addSuffix: true })}</p>
                                    </div>
                                </div>
                            </Link>
                        }) : <h3 className={showFilters && 'hide_for_mobile'}>No Results.</h3>
                        }
                </div>
            </div>
        ) 
    }
    
    if(category === 'phones'){
        const filteredListings = listings.filter((listing) => {

            if(phoneBrand == '' || location == ''){
                return listing;
            }

            if(listing.price >= minPrice && listing.price <= maxPrice && listing.phoneBrand == phoneBrand && listing.location == location){
                return listing
            }
    
        });

        return(
            <div className='listings_container'>
                <div className={showFilters ? `product_filter show` : `product_filter hide`}>
                    <div className='price_filter'>
                    <h5>Location</h5>
                    <select onChange={(e) => setLocation(e.target.value)}>
                        <option value=''>--</option>
                        {
                            locations.map((location, index) => (
                                <option key={index}>{ location }</option>
                            ))
                        }
                    </select>
                        <h5>Budget:</h5>
                        <div className='budget'>
                            <input type='number' placeholder='min' onChange={(e) => {
                                if(e.target.value == ''){
                                    setMinPrice(0)
                                } else {
                                setMinPrice(parseInt(e.target.value))}
                                }}/>
                            <input type='number' placeholder='max' onChange={(e) => {
                                if(e.target.value == ''){
                                    setMaxPrice(999999999)
                                } else {
                                setMaxPrice(parseInt(e.target.value))}}
                                }/>
                        </div>
                    </div>

                    <label>Brand:</label>
                    <select onChange={(e) => setPhoneBrand(e.target.value)}>
                        { phones.map((phone, index) => (
                            <option key={index}>{ phone }</option>
                        ))}
                    </select>
                   
                    <button className='button hide_for_desktop' onClick={() => setShowFilters(false)}>Apply Filters</button> 
                </div>
                <div className="listings no_block">
                { !showFilters &&  <button className='button hide_for_desktop' onClick={() => setShowFilters(true)}>Show Filters</button> }
                { filteredListings.length > 0 && !showFilters? 
                        filteredListings.map((listing) => {
                            return <Link to={`/listing/${listing._id}`} key={listing._id}>
                                <div className="card">
                                    <div className='image_box'>
                                        <img src={listing.images[0]} alt={`${listing.title}`} />
                                        <h3>{ listing.title }</h3>
                                        <p>{ listing.description.substring(0, 50) }....</p>
                                        <h4>{ listing.price } Ksh.</h4>
                                        <p className='card_time'>{formatDistanceToNow(new Date(listing.createdAt), { addSuffix: true })}</p>
                                    </div>
                                </div>
                            </Link>
                        }) : <h3 className={showFilters && 'hide_for_mobile'}>No Results.</h3>
                        }
                </div>
            </div>
        ) 
    }

    if(category === 'cameras'){
        const filteredListings = listings.filter((listing) => {

            if(cameraBrand == '' || location == ''){
                return listing;
            }

            if(listing.price >= minPrice && listing.price <= maxPrice && listing.cameraBrand == cameraBrand && listing.location == location){
                return listing
            }
    
        });

        return(
            <div className='listings_container'>
                <div className={showFilters ? `product_filter show` : `product_filter hide`}>
                    <div className='price_filter'>
                    <h5>Location</h5>
                    <select onChange={(e) => setLocation(e.target.value)}>
                        <option value=''>--</option>
                        {
                            locations.map((location, index) => (
                                <option key={index}>{ location }</option>
                            ))
                        }
                    </select>
                        <h5>Budget:</h5>
                        <div className='budget'>
                            <input type='number' placeholder='min' onChange={(e) => {
                                if(e.target.value == ''){
                                    setMinPrice(0)
                                } else {
                                setMinPrice(parseInt(e.target.value))}
                                }}/>
                            <input type='number' placeholder='max' onChange={(e) => {
                                if(e.target.value == ''){
                                    setMaxPrice(999999999)
                                } else {
                                setMaxPrice(parseInt(e.target.value))}}
                                }/>
                        </div>
                    </div>

                    <label>Brand:</label>
                    <select onChange={(e) => setCameraBrand(e.target.value)}>
                        { cameras.map((camera, index) => (
                            <option key={index}>{ camera }</option>
                        ))}
                    </select>
                   
                    <button className='button hide_for_desktop' onClick={() => setShowFilters(false)}>Apply Filters</button> 
                </div>
                <div className="listings no_block">
                { !showFilters &&  <button className='button hide_for_desktop' onClick={() => setShowFilters(true)}>Show Filters</button> }
                { filteredListings.length > 0 && !showFilters? 
                        filteredListings.map((listing) => {
                            return <Link to={`/listing/${listing._id}`} key={listing._id}>
                                <div className="card">
                                    <div className='image_box'>
                                        <img src={listing.images[0]} alt={`${listing.title}`} />
                                        <h3>{ listing.title }</h3>
                                        <p>{ listing.description.substring(0, 50) }....</p>
                                        <h4>{ listing.price } Ksh.</h4>
                                        <p className='card_time'>{formatDistanceToNow(new Date(listing.createdAt), { addSuffix: true })}</p>
                                    </div>
                                </div>
                            </Link>
                        }) : <h3 className={showFilters && 'hide_for_mobile'}>No Results.</h3>
                        }
                </div>
            </div>
        ) 
    }

    if(category === 'tv'){
        const filteredListings = listings.filter((listing) => {

            if( tvBrand == '' || location == ''){
                return listing;
            }

            if(listing.price >= minPrice && listing.price <= maxPrice && listing.tvBrand == tvBrand && listing.location == location){
                return listing
            }
    
        });

        return(
            <div className='listings_container'>
                <div className={showFilters ? `product_filter show` : `product_filter hide`}>
                    <div className='price_filter'>
                    <h5>Location</h5>
                    <select onChange={(e) => setLocation(e.target.value)}>
                        <option value=''>--</option>
                        {
                            locations.map((location, index) => (
                                <option key={index}>{ location }</option>
                            ))
                        }
                    </select>
                        <h5>Budget:</h5>
                        <div className='budget'>
                            <input type='number' placeholder='min' onChange={(e) => {
                                if(e.target.value == ''){
                                    setMinPrice(0)
                                } else {
                                setMinPrice(parseInt(e.target.value))}
                                }}/>
                            <input type='number' placeholder='max' onChange={(e) => {
                                if(e.target.value == ''){
                                    setMaxPrice(999999999)
                                } else {
                                setMaxPrice(parseInt(e.target.value))}}
                                }/>
                        </div>
                    </div>

                    <label>Brand:</label>
                    <select onChange={(e) => setPhoneBrand(e.target.value)}>
                        { tvs.map((tv, index) => (
                            <option key={index}>{ tv }</option>
                        ))}
                    </select>
                   
                    <button className='button hide_for_desktop' onClick={() => setShowFilters(false)}>Apply Filters</button> 
                </div>
                <div className="listings no_block">
                { !showFilters &&  <button className='button hide_for_desktop' onClick={() => setShowFilters(true)}>Show Filters</button> }
                { filteredListings.length > 0 && !showFilters? 
                        filteredListings.map((listing) => {
                            return <Link to={`/listing/${listing._id}`} key={listing._id}>
                                <div className="card">
                                    <div className='image_box'>
                                        <img src={listing.images[0]} alt={`${listing.title}`} />
                                        <h3>{ listing.title }</h3>
                                        <p>{ listing.description.substring(0, 50) }....</p>
                                        <h4>{ listing.price } Ksh.</h4>
                                        <p className='card_time'>{formatDistanceToNow(new Date(listing.createdAt), { addSuffix: true })}</p>
                                    </div>
                                </div>
                            </Link>
                        }) : <h3 className={showFilters && 'hide_for_mobile'}>No Results.</h3>
                        }
                </div>
            </div>
        ) 
    }

    return(
        <div className='listings_container'>
            <div className={showFilters ? `product_filter show` : `product_filter hide`}>
                <div className='price_filter'>
                    <h5>Location</h5>
                    <select onChange={(e) => setLocation(e.target.value)}>
                        <option value=''>--</option>
                        {
                            locations.map((location, index) => (
                                <option key={index}>{ location }</option>
                            ))
                        }
                    </select>
                    <h5>Budget:</h5>
                    <div className='budget'>
                        <input type='number' placeholder='min' onChange={(e) => {
                            if(e.target.value == ''){
                                setMinPrice(0)
                            } else {
                            setMinPrice(parseInt(e.target.value))}
                            }}/>
                        <input type='number' placeholder='max' onChange={(e) => {
                            if(e.target.value == ''){
                                setMaxPrice(999999999)
                            } else {
                            setMaxPrice(parseInt(e.target.value))}}
                            }/>
                    </div>
                     <button className='button hide_for_desktop' onClick={() => setShowFilters(false)}>Apply Filters</button> 
                </div>
            </div> 
            <div className="listings no_block">
            { !showFilters &&  <button className='button hide_for_desktop' onClick={() => setShowFilters(true)}>Show Filters</button> }
            { filteredListings.length > 0 && !showFilters ? 
                    filteredListings.map((listing) => {
                        return <Link to={`/listing/${listing._id}`} key={listing._id}>
                            <div className="card">
                                <div className='image_box'>
                                    <img src={listing.images[0]} alt={`${listing.title}`} />
                                    <h3>{ listing.title }</h3>
                                    <p>{ listing.description.substring(0, 50) }....</p>
                                    <h4>{ listing.price } Ksh.</h4>
                                    <p className='card_time'>{formatDistanceToNow(new Date(listing.createdAt), { addSuffix: true })}</p>
                                </div>
                            </div>
                        </Link>
                    }) : <h3 className={showFilters && 'hide_for_mobile'}>No Results.</h3>
                    }
            </div>
        </div>
    )
}