import { useState, useEffect, useRef } from "react"
import Conversations from "../components/Conversations";
import Message from "../components/Message";
import './css/Messages.css';

export default function Messages() {
    const [conversations, setConversations] = useState([]);
    const [currentChat, setCurrentChat] = useState(false);
    const [messages, setMessages] = useState([]);
    const [newMessage, setNewMessage] = useState('');

    const user = JSON.parse(localStorage.getItem('user'));

    const scrollRef = useRef();
    const inputRef = useRef();

    const getConversations = async() => {
        const response = await fetch(`https://sawwan.onrender.com/api/conversations/${user.id}`);
        const json = await response.json();
        setConversations(json);
    }

    const getMessages = async () => {
        const response = await fetch(`https://sawwan.onrender.com/api/messages/${currentChat._id}`);
        const json = await response.json();
        setMessages(json);
    }


    const handleSubmit = async (e) => { 
        e.preventDefault();

        if(newMessage == ''){
            return;
        }

        const message = {
            sender: user.id,
            text: newMessage,
            conversationId: currentChat._id
        };

        const response = await fetch(`https://sawwan.onrender.com/api/messages/`, {
            method: 'POST',
            body: JSON.stringify(message),
            headers: {
                'Content-Type': 'application/json'
            }
        });

        setMessages([...messages, message]);
        setNewMessage('');

    }

    const inputFocus = () => {
        inputRef.current.focus();
    }

    useEffect(() => {
        getConversations();
        getMessages();
    }, [currentChat]);

    useEffect(() => {
        // scrollRef.current?.scrollIntoView({ behavior: 'smooth'});
    }, [messages])

    return(
        <div className="messenger">
{ conversations.length < 1 ?  <div className="center"><h2>No messages yet.</h2></div> :
<>
            <div className={currentChat ? 'chat_menu hide_for_mobile' : 'chat_menu'}>
                <div className="chat_menu_wrapper">
                    <h2>Messages</h2>
                    {
                        conversations && conversations.map((convo) => (
                            <div key={convo._id} onClick={() => {
                                setCurrentChat(convo);
                                inputFocus();
                                }}>
                                <Conversations convo={convo} messages={messages}/>
                            </div>
                        ))
                    }
                </div>
            </div>
            <div className={currentChat ? 'chat_box' : "chat_box hide_for_mobile"}>
                <div className="chat_box_wrapper">
                    { currentChat  ?
                    <>
                    <button className="hide_for_desktop" onClick={() => setCurrentChat(false)}>Back</button>
                    <br></br>
                    <div className="chat_box_top">
                        {messages.map((message, index) => (
                            <div key={index} ref={scrollRef}>
                                <Message message={message}/>
                            </div>
                        ))}
                    </div>
                    <div className="chat_box_bottom">
                        <textarea className='chat_message_input' placeholder="Write a message..." 
                        value={newMessage} onChange={(e) => setNewMessage(e.target.value)}
                        ref={inputRef}>

                        </textarea>
                        <button className="chat_submit_button" onClick={handleSubmit}>Send</button>

                    </div>

                        </>
                        : <h3>Click to Start a Converstation</h3>}
                </div>
            </div>
        </>
}
        </div>
    )
}