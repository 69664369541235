export default function AboutPage() {
    return(
        <main className="info_page">
            <section className="info_section">
                <h2 className="info_title">About Us</h2>
                <p className="info_description">
                Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nulla sed aliquam magna, nec commodo nunc. Sed tempor suscipit leo, quis egestas magna commodo non. Maecenas erat neque, porta vitae ex placerat, hendrerit rutrum nunc. Aliquam erat volutpat. Sed rhoncus vehicula mauris. Donec vel diam pharetra, molestie augue eu, semper neque. Morbi vulputate nibh elit, non facilisis sapien porta ut. Nam sit amet urna efficitur, vestibulum diam quis, dictum metus. Integer vestibulum est eget nulla consectetur pretium ac ac justo. Ut quam nunc, ornare ac imperdiet tempus, ornare sit amet lorem. Ut sollicitudin elit nec eros cursus, eu finibus enim aliquam. Donec lacinia velit id enim aliquam, gravida tincidunt dui congue.
                Sed nec tincidunt dolor. Morbi at interdum mauris, nec dignissim nibh. Maecenas tristique tincidunt ante, eget semper metus tincidunt ac. Curabitur at dictum justo. Cras pharetra est est, ut cursus elit viverra non. Donec rhoncus posuere congue. Proin facilisis velit at augue tempor, quis rhoncus eros malesuada. Donec id leo augue. Quisque aliquet non massa non bibendum. Aenean nec eros posuere, commodo metus aliquet, pellentesque sapien. In feugiat est ultrices pharetra tristique. Praesent magna dolor, pretium id sagittis at, sollicitudin quis diam. Sed sed lectus eget purus blandit congue.
                Morbi lorem eros, sodales id viverra maximus, porttitor nec neque. Nulla facilisi. Etiam cursus euismod mauris in bibendum. Nunc sed luctus nisl. Sed rhoncus ligula vitae dictum convallis. Etiam ut quam eros. Vestibulum ac luctus arcu, a condimentum eros. Sed quis metus at magna aliquet vulputate eu vel leo. In hac habitasse platea dictumst. Praesent ut laoreet lorem. Suspendisse tristique eleifend ex, vitae consectetur diam rutrum non.
                Nullam placerat lorem quis suscipit aliquet. Proin tempor placerat aliquam. Fusce viverra arcu in nisl pharetra viverra. Quisque vel tempor turpis. Nam sagittis nibh vel euismod feugiat. Nullam et tortor tellus. Maecenas dapibus accumsan ex, in semper ligula pretium dapibus. Mauris finibus nulla dui, in interdum mi vehicula ac. Etiam eget velit nec enim malesuada euismod non vel mauris. In ultrices eros pulvinar urna aliquet semper. Nulla commodo mauris vel elit sagittis, nec dignissim lacus suscipit. In malesuada lorem enim, nec molestie justo malesuada ut. Pellentesque placerat risus non felis ullamcorper, ut condimentum ex congue.
                Sed quis viverra eros. Ut iaculis felis at felis dignissim dignissim at nec purus. Suspendisse mattis, quam at tempus tincidunt, purus leo consequat augue, quis accumsan eros massa sed nisl. Suspendisse convallis at urna volutpat rhoncus. Quisque ac leo sem. Suspendisse eu elit non lectus porttitor consectetur id non tellus. Maecenas aliquam leo non nisi venenatis dictum. Pellentesque fringilla tempus nibh a ultricies. Duis at sem ut lacus dapibus viverra vel et quam. Vestibulum sodales faucibus laoreet. Donec quis lacinia purus. Fusce tincidunt vehicula elit, sit amet lacinia nisl placerat vitae. Nunc dolor quam, finibus non lectus ut, consectetur interdum metus. Ut mauris felis, viverra et pretium viverra, bibendum ut lectus. Morbi maximus diam sapien. Proin felis turpis, placerat vel lobortis id, viverra et magna. 
                </p>
            </section>
        </main>
    )
}