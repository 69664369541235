import { useState } from "react";
import { Link } from 'react-router-dom';
import './css/Post.css';

export default function Post() {
    const [toggleState, setToggleState] = useState(0);
    const [currentCategory, setCurrentCategory] = useState('Vehicles');
    const [category, setCategory] = useState([
        {
            name: 'Vehicles',
            sub: [{name: 'Cars', url: 'cars'}, { name:'Motorbikes & Scooters', url: 'motorbikes'},  { name: 'Trucks, Vans & Buses', url: 'trucks' }, { name: 'Auto Parts & Accessories', url: 'parts' }, { name: 'Bicycles and Three Wheelers', url: 'bikes'}]
        }, 
        {
            name: 'Properties',
            sub: [{ name: 'For Sale: Houses & Apartments', url: 'for-sale-houses' }, { name: 'For Rent: Houses & Apartments', url: 'for-rent-houses'}, { name: 'For Sale: Commerical Property', url: 'for-sale-commercial'}, { name: 'For Rent: Commercial Property', url: 'for-rent-commercial'} , { name: 'Plots & Land', url: 'land'}]
        }, 
        {
            name: 'Technology',
            sub: [{ name: 'Computers', url: 'computers' }, { name: 'Phones', url: 'phones' }, { name: 'Cameras', url: 'cameras' }, { name: 'TV', url: 'tv ' }, { name: 'Other', url: 'tech-other' }]
        },
        {
            name: 'Pets',
            sub: [{ name: 'Pets', url: 'pets' }, { name: 'Farm Animals', url: 'farm-animals' }, { name: 'Other', url: 'pets-other'}]
        }, 
        {
            name: 'Baby',
            sub: [{ name: 'Toys', url: 'toys' }, { name: 'Baby Care', url: 'baby-care' }, { name: 'Baby Clothes', url: 'baby-clothes' }, { name: 'Parms & Strollers', url: 'strollers' }, { name: 'Accessories', url: 'accessories'}]
        }, 
        {
            name: 'Fashion',
            sub: [{ name: 'Men\'s Clothes', url: 'mens-clothes' }, { name: 'Women\'s Clothes', url: 'womens-clothes' }, { name: 'Men\'s Shoes', url: 'mens-shoes' }, { name: 'Women\'s Shoes', url: 'womens-shoes' }, { name: 'Watches', url: 'watches' }, { name: 'Jewellery', url: 'jewellery'}]
        },
        {
            name: 'Jobs',
            sub: [{ name: 'Accounting', url: 'jobs-accounting'}, { name: 'Advertisting', url: 'jobs-advertising'}, { name: 'Child Care', url: 'jobs-child-care'}, { name: 'Construction', url: 'jobs-construction'}, { name: 'Computer& IT', url: 'jobs-computer-it'}, { name: 'Driver', url: 'jobs-driver'}, { name: 'Engineering', url: 'jobs-engineering'}, { name: 'Farming', url: 'jobs-farming'}, { name: 'Gardening', url: 'jobs-gardening'}, { name: 'Hotel', url: 'jobs-hotel'}, { name: 'Legal', url: 'jobs-legal'}, { name: 'Restaurant', url: 'jobs-restaurant'}, { name: 'Retail', url: 'jobs-retail'}, { name: 'Security', url: 'jobs-security'}]
            // sub: [{ name: 'Accounting', url: 'accounting'}, { name: 'Advertisting', url: 'advertising'}, { name: 'Child Care', url: 'child-care'}, { name: 'Construction', url: 'construction'}, { name: 'Computer& IT', url: 'computer-it'}, { name: 'Driver', url: 'driver'}, { name: 'Engineering', url: 'engineering'}, { name: 'Farming', url: 'farming'}, { name: 'Gardening', url: 'gardening'}, { name: 'Hotel', url: 'hotel'}, { name: 'Legal', url: 'legal'}, { name: 'Restaurant', url: 'restaurant'}, { name: 'Retail', url: 'retail'}, { name: 'Security', url: 'security'}]
        },
        {
            name: 'Furniture',
            sub: [{ name: 'Furniture', url: 'furniture'} , { name: 'Home Accessories', url: 'home'}, { name: 'Kitchen Ware', url: 'kitchen'}]
        }, 
        {
            name: 'Agriculture',
            sub: [{name: 'Farm Equipment', url: 'farm-equipment'}, { name: 'Livestock', url: 'livestock'}, {name: 'Livestock Feed', url: 'livestock-feed'}, { name: 'Farm Produce', url: 'farm-produce'}]
        }, 
        {
            name: 'Tools',
            sub: [{name: 'Garage Tools', url: 'garage-tools'}, { name: 'Manufacturing Equipment', url: 'manufacturing'}, {name: 'Garden Tools', url: 'garden-tools'}, { name: 'Printing Equipment', url: 'printing-equipment'}, { name: 'Other', url: 'tools-other'}]
        },
        {
            name: 'Services',
            sub: [{name: 'Tickets', url: 'tickets '}, { name: 'Travel & Visa', url: 'visas'}, { name: 'Business & Technical Service', url: 'business-services'},    { name: 'Domestic & Personal', url: 'domestic' }, { name: 'Events & Hospitality', url: 'events'}]
        }
    ]);

    const [showTabOptions, setShowTabOptions] = useState(false);


    const toggleTab = (index, name) => {
        setToggleState(index);
        setCurrentCategory(name);
        setShowTabOptions(true)
    }

    const filteredList = category.filter((item) => item.name == currentCategory);

    return(
        <div className="main"> 
        <h1 className="categories">CHOOSE THE BEST MATCH</h1>
            <div className="tabs_container">

                <div className={`${!showTabOptions ? 'showOptions' : 'hideOptions'} tab_block`}>

                    {category.map((result, index) => {
                        return(
                            <div 
                            className={toggleState == index ? "tabs active_tab": "tabs"}
                            key={index}
                            onClick={() => toggleTab(index, result.name)}>
                                <p>{ result.name }</p>
                                <p>{'>'}</p>
                            </div>
                        )
                    })}
                </div>

                <div className={`${showTabOptions ? 'showOptions' : 'hideOptions'} tab_content`}>
                   {filteredList.map((result) => (
                    result.sub.map((res, index) => (
                        <Link to={`/createpost/${res.url}`} key={index}><p>{ res.name }</p> <p>{'>'}</p></Link>
                    ))
                   ))}
                </div>
            </div>
        </div>
    )
}