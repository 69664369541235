import { useEffect, useState } from 'react';
import '../pages/css/Conversations.css';

export default function Conversations({ convo, messages }) {
    const user = JSON.parse(localStorage.getItem('user'));
    const [friend, setFriend] = useState(false);
    const [lastConvo, setLastConvo] = useState([]); 
    const friendId = convo.members.find((member) => member != user.id);


    const fetchUser = async () => {
        if(user){
            const response = await fetch(`http://localhost:4000/api/users/${friendId}`);
            const json = await response.json();
            setFriend(json);
        }
    }

    const fetchConversation = async () => {
        const response = await fetch(`http://localhost:4000/api/messages/${convo._id}`);
        const json = await response.json();
        setLastConvo(json[json.length - 1].text);
    }

    
    useEffect(() => {
        fetchUser();
        fetchConversation();
    }, [convo, messages])
    return(
        <div className='conversations'>
            <div className='chat_profile'>   
                <img src={friend.image} alt='profile_picture'/>
            </div>
            <div>
                { friend && <p className='conversation_name'>{ friend.first_name } { friend.last_name }</p> }
                <p className='conversation_preview'>{ lastConvo && lastConvo}</p>
            </div>
        </div>
    )
}